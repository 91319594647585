import React from "react";
import theme from "theme";
import { Theme, Link, Text, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { Override, StackItem, Stack, Formspree, Section } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<Helmet>
			<title>
				Contact - kursin.com - modern web development
			</title>
			<meta name={"description"} content={"Launch your startup in a couple of weeks. We are creating great web or mobile applications quickly using modern NoCode tools or pure code."} />
			<meta property={"og:title"} content={"Contact - kursin.com - modern web development"} />
			<meta property={"og:description"} content={"Launch your startup in a couple of weeks. We are creating great web or mobile applications quickly using modern NoCode tools or pure code."} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1589561253898-768105ca91a8?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
			<script async src={`https://www.googletagmanager.com/gtag/js?id=UA-176560010-1`}></script>
     		<script>
			{`
      	    window.dataLayer = window.dataLayer || [];
          	function gtag(){dataLayer.push(arguments);}
          	gtag('js', new Date());
          	gtag('config', "UA-176560010-1");
        	`}
		   	</script>
		</Helmet>
		<Components.Header />
		<Section
			box-sizing="border-box"
			quarkly-title="Contacts"
			padding="50px 0px 50px 0px"
			lg-padding="25px 0px 25px 0px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
			/>
			<Stack justify-content="flex-start" align-items="stretch" gap="--cmp-stack-gap-default">
				{"    "}
				<StackItem
					width="100%"
					display="flex"
					quarkly-title="Form"
					md-width="100%"
					sm-width="100%"
					md-margin="0px 0px 24px 0px"
					sm-margin="0px 0px 16px 0px"
				>
					<Override
						slot="StackItemContent"
						padding="48px 36px 54px 36px"
						flex-direction="column"
						sm-padding="24px 16px 24px 16px"
						background="--color-lightD1"
						md-padding="24px 36px 36px 36px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
					/>
					{"        "}
					<Text
						font="normal 300 40px/1.2 --fontFamily-googleMontserrat"
						margin="0px 0px 15px 0px"
						display="inline-block"
						quarkly-title="Title"
						md-font="--headline3"
					>
						Contact us
					</Text>
					<Text
						font="--base"
						margin="0px 0px 28px 0px"
						display="inline-block"
						quarkly-title="Description"
						color="--dark"
						md-font="--base"
					>
						Please les us know if you need calculate price for your project or if you have another questions about our work.
						<br />
						We will answer you as soon as possible.
					</Text>
					<Formspree endpoint="mpzojnap">
						<Stack gap="16px">
							<StackItem width="100%">
								<Override slot="StackItemContent" flex-direction="column" />
								<Text font="--base" margin="0 0 4px 0">
									Name
								</Text>
								<Input max-width="400px" width="100%" name="name" placeholder="Enter your name" />
							</StackItem>
							<StackItem width="100%">
								<Override slot="StackItemContent" flex-direction="column" />
								<Text font="--base" margin="0 0 4px 0">
									Email
								</Text>
								<Input
									max-width="400px"
									width="100%"
									type="email"
									name="email"
									placeholder="Enter your e-mail"
								/>
							</StackItem>
							<StackItem width="100%">
								<Override slot="StackItemContent" flex-direction="column" />
								<Text font="--base" margin="0 0 4px 0">
									Message
								</Text>
								<Input
									as="textarea"
									rows="4"
									width="100%"
									name="message"
									max-width="800px"
									placeholder="Please describe your question"
								/>
							</StackItem>
							<StackItem width="100%">
								<Button>
									Send
								</Button>
							</StackItem>
						</Stack>
					</Formspree>
					{"    "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Components.FooterMain>
			<Override slot="stackItem" lg-width="100%" sm-width="100%" />
		</Components.FooterMain>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
	</Theme>;
});